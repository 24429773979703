var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', {
    staticClass: "pt-56 pb-24"
  }, [_c('section', {
    staticClass: "effect--fade-slide-in"
  }, [_c('div', {
    staticClass: "section--container section--container-page"
  }, [_c('div', {
    staticClass: "text-center section--content"
  }, [_vm.error.statusCode === 404 ? _c('h2', [_vm._v("Page not found")]) : _c('h2', [_vm._v("An error occurred")]), _vm._v(" "), _c('a', {
    staticClass: "block mt-4 opacity-50",
    attrs: {
      "href": "/"
    }
  }, [_vm._v("Back to the home page")])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };