import { Storage } from './password-protect/storage';
import { getPasswordProtect } from './password-protect/main';
import Middleware from './middleware';
Middleware.passwordProtect = Middleware['password-protect'] = function (ctx) {
  ctx.$passwordProtect.checkUserIfRedirect();
};
const defaultOptions = {
  queryString: '_password',
  tokenSeed: 103094582309,
  cookieName: '_password',
  formPath: '/password',
  password: 'password',
  cookie: {
    prefix: '',
    expires: 2
  }
};
export default ((ctx, inject) => {
  const options = {
    ...defaultOptions,
    ...JSON.parse('{"formPath":"/password","password":"getextra","tokenSeed":"pp0s2bu5","queryString":"_password","cookieName":"extra_password","ignoredPaths":["/members","/members/","/members/card","/members/card/","/members/login","/members/login/","/members/logout","/members/logout/","/application","/application/","/friendpass","/friendpass/","/verify-docs","/verify-docs/"],"cookie":{"prefix":""}}')
  };
  const storage = new Storage(ctx, options);
  const $passwordProtect = getPasswordProtect({
    options,
    ctx,
    storage
  });
  inject('passwordProtect', $passwordProtect);
  ctx.$passwordProtect = $passwordProtect;
  return $passwordProtect;
});