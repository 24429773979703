import _ from 'lodash';
export default {
  props: {
    showOnlyDisclosure: {
      type: Boolean,
      default: false
    },
    showBlackLogo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  methods: {
    onSignupClick(source, label) {
      const handler = this.$route && this.$route.matched.map(r => {
        return _.get(r.components.default, 'options.methods.onSignupClick') || _.get(r.components.default, 'methods.onSignupClick');
      })[0];
      if (handler) {
        handler.call(this, source, label);
      } else {
        this.$router.push({
          path: '/application',
          query: {
            device_tag: window.g_device_tag
          }
        });
      }
    }
  }
};