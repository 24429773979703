import _ from 'lodash';
export default {
  name: 'PageHeader',
  props: {
    fixed: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    centered: {
      type: Boolean,
      default: false
    },
    logoHref: {
      type: String,
      default: '/'
    },
    showLogo: {
      type: Boolean,
      default: true
    },
    showApplyNow: {
      type: Boolean,
      default: true
    },
    showLoginLink: {
      type: Boolean,
      default: false
    },
    loginQuery: {
      type: String,
      default: ''
    }
  },
  methods: {
    onSignupClick(source, label) {
      const handler = this.$route && this.$route.matched.map(r => {
        return _.get(r.components.default, 'options.methods.onSignupClick') || _.get(r.components.default, 'methods.onSignupClick');
      })[0];
      if (handler) {
        handler.call(this, source, label);
      } else {
        this.$router.push({
          path: '/application',
          query: {
            device_tag: window.g_device_tag
          }
        });
      }
    },
    onLoginClick() {
      this.$analyticsHub.track('Login Link Clicked');
    }
  }
};