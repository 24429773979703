/* eslint-disable no-constant-condition */
// import jwt from 'jsonwebtoken'
const LOGIN_PATH = '/login';
function decline(ctx) {
  console.warn('[mdwr] declined');
  ctx.app.$cookies.remove('members_token');
  return ctx.redirect(LOGIN_PATH);
}
// not a tracker, just a middleware to check if user is logged in
export default function (ctx) {
  // console.log('[mdwr] init')
  if (ctx.route.path !== LOGIN_PATH) {
    const token = ctx.app.$cookies.get('members_token');
    const memberId = ctx.app.$cookies.get('extra_member_id');
    const memberTag = ctx.app.$cookies.get('extra_member_tag');
    if (token) {
      if ((!memberId || !memberTag) && false) {
        console.log('[mdwr] using fetch');
        const axiosReq = ctx.app.$axios.$get('/api/members/profile', {
          headers: {
            Authorization: `Bearer ${token}`
          },
          baseURL: ctx.env.BASE_URL
        });
        console.log('[mdwr] base url', axiosReq);
        return axiosReq.then(response => {
          if (response) {
            ctx.app.$cookies.set('extra_member_id', response.id);
            ctx.app.$cookies.set('extra_member_tag', response.tag);
            console.log('[mdwr] fetch verified, hey', response.form && response.form.first_name);
            return true;
          }
        }).catch(err => {
          if (process.server) {
            console.log('[mdwr] fetch error', err);
          }
          return decline(ctx);
        });
      } else {
        // const verified = jwt.decode(token)
        const verified = !!token;
        // console.log('[mdwr] using jwt')
        if (verified) {
          // const payload = jwt.decode(token)
          // console.log('[mdwr] jwt verified')
          // ctx.app.$cookies.set('extra_member_id', payload.member_id && `member_${payload.member_id}`)
          // ctx.app.$cookies.set('extra_member_id', payload.member_id && `member_${payload.member_id}`)
          // ctx.app.$cookies.set('extra_member_tag', payload.member_tag)
          console.log('[mdwr] jwt', ctx.app.$cookies.get('member_id'), ctx.route.path);
          return true;
        } else {
          // console.log('[mdwr] jwt declined')
          return decline(ctx);
        }
      }
    }
    if (!token) {
      // console.log('[mdwr] no token')
      // return decline(ctx)
    }
  }
}