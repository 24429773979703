export default function (ctx) {
  if (process.server) {
    const query = ctx.req.query;
    if (query && query.nowall !== undefined) {
      ctx.app.$cookies.set('extra_exp_nowall', true, {
        path: '/'
      });
      // return ctx.redirect('/')
    }
    // AFF-1
    if (query && (!!query.prdg_id || !!query.aff_sub3)) {
      ctx.app.$cookies.set('extra_prdg_id', query.prdg_id || query.aff_sub3, {
        path: '/',
        domain: extractDomain(ctx.req.headers.host)
      });
    }
    if (query && query.utm_source === 'tapjoy' && !!query.txid) {
      ctx.app.$cookies.set('extra_tj_id', query.txid, {
        path: '/',
        domain: extractDomain(ctx.req.headers.host)
      });
    }
    if (query && !!query.finteltag) {
      ctx.app.$cookies.set('extra_fintel_id', query.finteltag, {
        path: '/',
        domain: extractDomain(ctx.req.headers.host)
      });
    }
    if (query && query.utm_source === 'banneredge' && !!query.s1 && !!query.s2) {
      ctx.app.$cookies.set('extra_bem_id', `${query.s1}|${query.s2}`, {
        path: '/',
        domain: extractDomain(ctx.req.headers.host)
      });
    }
    if (query && query.utm_source === 'lockerdome' && !!query.click_id) {
      ctx.app.$cookies.set('extra_lockerdome_id', query.click_id, {
        path: '/',
        domain: extractDomain(ctx.req.headers.host)
      });
    }
    return true;
  }
}
function extractDomain(h) {
  return '.' + ([(h || '').split('.').reverse()[1], (h || '').split('.').reverse()[0]].filter(e => e).join('.').split(':')[0] || 'extra.app');
}