// Documentation: https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/
async function getAnalytics(config) {
  const rudderanalytics = await import('rudder-sdk-js');
  return new Promise((resolve, reject) => {
    let timeout;
    try {
      rudderanalytics.load(config.rudderstackWriteKey, config.rudderstackDataPlaneUrl, {
        configUrl: config.rudderstackConfigUrl
      });
      timeout = setTimeout(() => {
        reject(new Error('[rudder] ready timeout'));
      }, 4000);
      rudderanalytics.ready(() => {
        timeout && clearTimeout(timeout);
        console.log('[rudder] ready');
        resolve(rudderanalytics);
      });
    } catch (e) {
      timeout && clearTimeout(timeout);
      reject(e);
    }
  });
}
export async function getRudder(config) {
  try {
    const analytics = await getAnalytics(config);
    return analytics;
  } catch (e) {
    console.warn('[rudder] failed to initialize', e);
    return null;
  }
}