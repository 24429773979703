import { generateMetaHead } from '~/utils/head';

// import LoadingOverlay from '~/components/common/LoadingOverlay.vue'

export default {
  components: {
    // LoadingOverlay,
  },
  layout: 'default',
  // headerFixed: false,

  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  data() {
    return {
      loaded: false,
      sliderValue: 5000
    };
  },
  head() {
    return generateMetaHead({
      routePath: this.$route.path
    });
  }
};