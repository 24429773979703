// EVERFLOW ENGAGEMENT EVENT
import { shouldAllowTrackers } from '~/utils/head';
// to be called on page load and each route change
export default function (ctx) {
  if (!process.browser) {
    return;
  }
  const allowTrackers = shouldAllowTrackers(ctx.route.path, window);
  if (!allowTrackers.Advertising) {
    console.log(`[ef] advertising trackers disallowed`);
    return;
  }
  if (ctx.route && ctx.route.path.includes('iframe_')) {
    console.log(`[ef] is iframe (${ctx.route.path})`);
    return;
  }
  if (!window.EF) {
    window.ef_click_on_load = true;
  } else {
    window.ef_click && window.ef_click();
  }
}