export const state = () => ({
  formSubmissionStatus: false,
  sanityData: {
    abTests: []
  }
});
export const mutations = {
  formSubmitted(state) {
    state.formSubmissionStatus = true;
  },
  setSanityData(state, {
    key,
    data
  }) {
    state.sanityData[key] = data;
  }
};