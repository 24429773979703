var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('header', {
    staticClass: "pointer-events-none",
    class: [_vm.fixed ? 'relative' : 'fixed', !_vm.backdrop && 'bg-none']
  }, [_c('div', {
    staticClass: "content",
    class: !_vm.centered ? 'justify-between' : 'justify-center'
  }, [_c('a', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLogo,
      expression: "showLogo"
    }],
    class: !_vm.logoHref ? 'pointer-events-none' : 'pointer-events-auto',
    attrs: {
      "href": _vm.logoHref
    }
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("assets/images/extra-logo-white.svg"),
      "alt": "Extra logo",
      "width": "115",
      "height": "18"
    }
  })]), _vm._v(" "), _vm.$slots.default ? _c('div', {
    staticClass: "pointer-events-auto links"
  }, [_vm._t("default")], 2) : !_vm.centered ? _c('div', {
    staticClass: "pointer-events-auto links"
  }, [_c('a', {
    staticClass: "sm-md:hidden",
    attrs: {
      "href": "https://extra.app/blog"
    }
  }, [_vm._v("Blog")]), _vm._v(" "), _c('a', {
    staticClass: "sm-md:hidden",
    attrs: {
      "href": "/help"
    }
  }, [_vm._v("FAQs")]), _vm._v(" "), _vm.showLoginLink ? _c('nuxt-link', {
    attrs: {
      "to": `/login${_vm.loginQuery}`
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.onLoginClick.apply(null, arguments);
      }
    }
  }, [_vm._v("Log in")]) : _vm._e(), _vm._v(" "), _vm.showApplyNow ? _c('button', {
    staticClass: "ml-2 button--filled button--mint",
    on: {
      "click": () => _vm.onSignupClick('header', 'apply_for_access')
    }
  }, [_c('span', [_vm._v("Get Started")])]) : _vm._e()], 1) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };