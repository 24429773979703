import _ from 'lodash';
export default function ({
  $axios
}) {
  $axios.onError(error => {
    const base = error.toJSON();
    if (base.config) {
      // base.config.data = '<redacted>'

      if (typeof base.config.data === 'object' && base.config.data.ssn) {
        base.config.data.ssn = '<redacted>';
      }
      if (typeof base.config.data === 'string' && JSON.parse(base.config.data)) {
        const obj = JSON.parse(base.config.data);
        if (obj.ssn) {
          obj.ssn = '<redacted>';
        }
        base.config.data = JSON.stringify(obj);
      }
    }
    error.toJSON = function () {
      _.set(base, 'config.headers.Authorization', '<redacted>');
      return {
        ...base,
        ..._.omit(error.response, ['request'])
      };
    };
    return Promise.reject(error);
  });
}