// this layout is responsible for initializing the head meta tags
// and handle tracking consent opt-out

// this layout should be inherited by every other layout
// and never be used on individual pages (use empty if you need to)

import { generateMetaHead, shouldAllowTrackers } from '~/utils/head';

// const OPT_OUT_SCRIPTS = [
// 	{
// 		hid: 'block-referrer',
// 		src: 'https://s3.extra.app/site-assets/block-referrer.min.js',
// 	},
// ]

export default {
  head() {
    const trackersAllowed = shouldAllowTrackers(this.$route.path, window);
    return generateMetaHead({
      routePath: this.$route.path,
      trackersAllowed
      // script: !trackersAllowed ? OPT_OUT_SCRIPTS : [],
    });
  },
  created() {
    console.log('hello from _base');
  }
};