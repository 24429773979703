var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "__password-layout"
    }
  }, [_c('nuxt')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };