import Vue from 'vue';
import Vuex from 'vuex';
import Meta from 'vue-meta';
import ClientOnly from 'vue-client-only';
import NoSsr from 'vue-no-ssr';
import { createRouter } from './router.js';
import NuxtChild from './components/nuxt-child.js';
import NuxtError from '../layouts/error.vue';
import Nuxt from './components/nuxt.js';
import App from './App.js';
import { setContext, getLocation, getRouteData, normalizeError } from './utils';
import { createStore } from './store.js';

/* Plugins */

import nuxt_plugin_sentryserver_4465b420 from 'nuxt_plugin_sentryserver_4465b420'; // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_7813b468 from 'nuxt_plugin_sentryclient_7813b468'; // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_recaptcha_7249ea9b from 'nuxt_plugin_recaptcha_7249ea9b'; // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_pluginclient_8855f2dc from 'nuxt_plugin_pluginclient_8855f2dc'; // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_218e781a from 'nuxt_plugin_pluginserver_218e781a'; // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_templatesplugind9d213c6_d28c9278 from 'nuxt_plugin_templatesplugind9d213c6_d28c9278'; // Source: ./templates.plugin.d9d213c6.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_d55bcc58 from 'nuxt_plugin_cookieuniversalnuxt_d55bcc58'; // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_vuescrollto_772c2a5d from 'nuxt_plugin_vuescrollto_772c2a5d'; // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_passwordprotect_5b346def from 'nuxt_plugin_passwordprotect_5b346def'; // Source: ./password-protect.js (mode: 'all')
import nuxt_plugin_axios_2e0a522c from 'nuxt_plugin_axios_2e0a522c'; // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_001a0492 from 'nuxt_plugin_deviceplugin_001a0492'; // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_plugin_4bd32964 from 'nuxt_plugin_plugin_4bd32964'; // Source: ./sanity/plugin.js (mode: 'all')
import nuxt_plugin_trackers_3ed155b1 from 'nuxt_plugin_trackers_3ed155b1'; // Source: ../plugins/trackers (mode: 'all')
import nuxt_plugin_axios_06be2d00 from 'nuxt_plugin_axios_06be2d00'; // Source: ../plugins/global/axios (mode: 'all')
import nuxt_plugin_vuethemask_43514a36 from 'nuxt_plugin_vuethemask_43514a36'; // Source: ../plugins/global/vue-the-mask (mode: 'client')
import nuxt_plugin_dompurify_6ce62108 from 'nuxt_plugin_dompurify_6ce62108'; // Source: ../plugins/global/dompurify.js (mode: 'client')
import nuxt_plugin_sanityData_71cec869 from 'nuxt_plugin_sanityData_71cec869'; // Source: ../plugins/global/sanityData.js (mode: 'server')
import nuxt_plugin_vueconfetti_9980819c from 'nuxt_plugin_vueconfetti_9980819c'; // Source: ../plugins/global/vue-confetti.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly);

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render(h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true;
      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead');
    }
    return NoSsr.render(h, ctx);
  }
});

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild);
Vue.component('NChild', NuxtChild);

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt);
Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null;
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt;
    }
    return globalNuxt;
  },
  configurable: true
});
Vue.use(Meta, {
  "keyName": "head",
  "attribute": "data-n-head",
  "ssrAttribute": "data-n-head-ssr",
  "tagIDKeyName": "hid"
});
const defaultTransition = {
  "name": "page",
  "mode": "out-in",
  "appear": false,
  "appearClass": "appear",
  "appearActiveClass": "appear-active",
  "appearToClass": "appear-to"
};
const originalRegisterModule = Vuex.Store.prototype.registerModule;
function registerModule(path, rawModule, options = {}) {
  const preserveState = process.client && (Array.isArray(path) ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state) : path in this.state);
  return originalRegisterModule.call(this, path, rawModule, {
    preserveState,
    ...options
  });
}
async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext);
  const router = await createRouter(ssrContext, config, {
    store
  });

  // Add this.$router into store actions/mutations
  store.$router = router;

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule;

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {
      "title": "Extra Debit Card® - Build Credit Without a Credit Card",
      "htmlAttrs": {
        "lang": "en"
      },
      "meta": [{
        "charset": "utf-8"
      }, {
        "name": "viewport",
        "content": "width=device-width, initial-scale=1"
      }, {
        "hid": "description",
        "name": "description",
        "content": "Make the most of everyday purchases. Build credit*, and earn reward points* on every purchase. No credit checks, no hidden fees, and no interest rates, ever."
      }, {
        "hid": "keywords",
        "name": "keywords",
        "content": "extra, debit, card, credit, credit building, rewards, points"
      }, {
        "hid": "og:type",
        "name": "og:type",
        "content": "website"
      }, {
        "hid": "og:url",
        "name": "og:url",
        "content": "https:\u002F\u002Fextra.app"
      }, {
        "hid": "og:title",
        "name": "og:title",
        "content": "Extra Debit Card® - Build Credit Without a Credit Card"
      }, {
        "hid": "og:description",
        "name": "og:description",
        "content": "Make the most of everyday purchases. Build credit*, and earn reward points* on every purchase. No credit checks, no hidden fees, and no interest rates, ever."
      }, {
        "hid": "og:image",
        "name": "og:image",
        "content": "https:\u002F\u002Fs3.extra.app\u002Fsite-assets\u002Fog-2022-1.jpg"
      }, {
        "hid": "twitter:card",
        "name": "twitter:card",
        "content": "summary_large_image"
      }, {
        "hid": "twitter:url",
        "name": "twitter:url",
        "content": "https:\u002F\u002Fextra.app"
      }, {
        "hid": "twitter:title",
        "name": "twitter:title",
        "content": "Extra Debit Card® - Build Credit Without a Credit Card"
      }, {
        "hid": "twitter:description",
        "name": "twitter:description",
        "content": "Make the most of everyday purchases. Build credit*, and earn reward points* on every purchase. No credit checks, no hidden fees, and no interest rates, ever."
      }, {
        "hid": "twitter:image",
        "name": "twitter:image",
        "content": "https:\u002F\u002Fs3.extra.app\u002Fsite-assets\u002Fog-2022-1.jpg"
      }],
      "link": [{
        "hid": "icon",
        "rel": "icon",
        "type": "image\u002Fx-icon",
        "href": "\u002Ffavicon.png"
      }],
      "script": [{
        "hid": "referral-pixel",
        "src": "\u002Fmin\u002Freferral-pixel.js",
        "async": true
      }, {
        "hid": "block-referrer",
        "src": "https:\u002F\u002Fs3.extra.app\u002Fsite-assets\u002Fblock-referrer.min.js"
      }],
      "style": []
    },
    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions(transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions];
        }
        transitions = transitions.map(transition => {
          if (!transition) {
            transition = defaultTransition;
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, {
              name: transition
            });
          } else {
            transition = Object.assign({}, defaultTransition, transition);
          }
          return transition;
        });
        this.$options.nuxt.transitions = transitions;
        return transitions;
      },
      err: null,
      errPageReady: false,
      dateErr: null,
      error(err) {
        err = err || null;
        app.context._errored = Boolean(err);
        err = err ? normalizeError(err) : null;
        let nuxt = app.nuxt; // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt;
        }
        nuxt.dateErr = Date.now();
        nuxt.err = err;
        nuxt.errPageReady = false;
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err;
        }
        return err;
      }
    },
    ...App
  };

  // Make app available into store via this.app
  store.app = app;
  const next = ssrContext ? ssrContext.next : location => app.router.push(location);
  // Resolve route
  let route;
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route;
  } else {
    const path = getLocation(router.options.base, router.options.mode);
    route = router.resolve(path).route;
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  });
  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided');
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`);
    }
    key = '$' + key;
    // Add into app
    app[key] = value;
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value;
    }

    // Add into store
    store[key] = app[key];

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__';
    if (Vue[installKey]) {
      return;
    }
    Vue[installKey] = true;
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get() {
            return this.$root.$options[key];
          }
        });
      }
    });
  }

  // Inject runtime config as $config
  inject('config', config);
  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state);
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData);
      inject('preview', previewData);
    };
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_4465b420 === 'function') {
    await nuxt_plugin_sentryserver_4465b420(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_sentryclient_7813b468 === 'function') {
    await nuxt_plugin_sentryclient_7813b468(app.context, inject);
  }
  if (typeof nuxt_plugin_recaptcha_7249ea9b === 'function') {
    await nuxt_plugin_recaptcha_7249ea9b(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_pluginclient_8855f2dc === 'function') {
    await nuxt_plugin_pluginclient_8855f2dc(app.context, inject);
  }
  if (process.server && typeof nuxt_plugin_pluginserver_218e781a === 'function') {
    await nuxt_plugin_pluginserver_218e781a(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_templatesplugind9d213c6_d28c9278 === 'function') {
    await nuxt_plugin_templatesplugind9d213c6_d28c9278(app.context, inject);
  }
  if (typeof nuxt_plugin_cookieuniversalnuxt_d55bcc58 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_d55bcc58(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_vuescrollto_772c2a5d === 'function') {
    await nuxt_plugin_vuescrollto_772c2a5d(app.context, inject);
  }
  if (typeof nuxt_plugin_passwordprotect_5b346def === 'function') {
    await nuxt_plugin_passwordprotect_5b346def(app.context, inject);
  }
  if (typeof nuxt_plugin_axios_2e0a522c === 'function') {
    await nuxt_plugin_axios_2e0a522c(app.context, inject);
  }
  if (typeof nuxt_plugin_deviceplugin_001a0492 === 'function') {
    await nuxt_plugin_deviceplugin_001a0492(app.context, inject);
  }
  if (typeof nuxt_plugin_plugin_4bd32964 === 'function') {
    await nuxt_plugin_plugin_4bd32964(app.context, inject);
  }
  if (typeof nuxt_plugin_trackers_3ed155b1 === 'function') {
    await nuxt_plugin_trackers_3ed155b1(app.context, inject);
  }
  if (typeof nuxt_plugin_axios_06be2d00 === 'function') {
    await nuxt_plugin_axios_06be2d00(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_vuethemask_43514a36 === 'function') {
    await nuxt_plugin_vuethemask_43514a36(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_dompurify_6ce62108 === 'function') {
    await nuxt_plugin_dompurify_6ce62108(app.context, inject);
  }
  if (process.server && typeof nuxt_plugin_sanityData_71cec869 === 'function') {
    await nuxt_plugin_sanityData_71cec869(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_vueconfetti_9980819c === 'function') {
    await nuxt_plugin_vueconfetti_9980819c(app.context, inject);
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.');
    };
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const {
        route
      } = router.resolve(app.context.route.fullPath);
      if (!route.matched.length) {
        return resolve();
      }
    }
    router.replace(app.context.route.fullPath, resolve, err => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err);
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve();

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath;
        }
        app.context.route = await getRouteData(to);
        app.context.params = to.params || {};
        app.context.query = to.query || {};
        unregister();
        resolve();
      });
    });
  });
  return {
    store,
    app,
    router
  };
}
export { createApp, NuxtError };