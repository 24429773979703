import PageFooter from '~/components/common/PageFooter.vue';
import { generateMetaHead } from '~/utils/head';
export default {
  components: {
    PageFooter
  },
  layout: '_base',
  head() {
    return generateMetaHead({
      routePath: this.$route.path
    });
  }
};