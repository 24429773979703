const analyticsHubMock = (_, inject) => {
  const noop = () => {};
  inject('analyticsHub', {
    track: noop,
    page: noop,
    identify: noop,
    alias: noop,
    getAnonymousId: () => Promise.resolve(null),
    setAnonymousId: noop
  });
};
export default analyticsHubMock;