import { generateMetaHead } from '~/utils/head';
export default {
  layout: '_base',
  head() {
    return generateMetaHead({
      routePath: this.$route.path,
      htmlAttrs: {
        class: 'theme-light'
      }
    });
  }
};