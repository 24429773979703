import { GrowthBook } from '@growthbook/growthbook';
import axios from 'axios';
export default async function ({
  app,
  query,
  route,
  $config
}, inject) {
  const anonymousId = await app.$analyticsHub.getAnonymousId();
  const clickId = query.fbclid && 'fbclid' || query.gclid && 'gclid' || app.$cookies.get('click_id') || '';
  const affiliateId = query.aff_id || query.affid || '';
  const partnerId = affiliateId || clickId || app.$cookies.get('extra_ef_affid') || '';
  const deviceType = app.$device.isDesktop && 'desktop' || app.$device.isMobile && 'mobile' || app.$device.isTablet && 'tablet' || 'desktop';
  const growthbookClient = new GrowthBook({
    attributes: {
      anonymousId,
      // Only include partnerId attribute if not empty
      ...(partnerId && {
        partnerId: `${partnerId}`
      })
    },
    trackingCallback: (experiment, result) => {
      const {
        value: resultValue,
        ...restOfResults
      } = result;
      const {
        key: experimentId,
        namespace
      } = experiment;
      const attributes = growthbookClient.getAttributes();
      const properties = {
        experimentId,
        namespace,
        resultValue,
        deviceType,
        ...restOfResults,
        ...attributes
      };
      app.$analyticsHub.track('Experiment Viewed', properties);
    }
  });
  try {
    const result = await axios.get($config.growthbookEndpoint);
    growthbookClient.setFeatures(result.data.features);
  } catch (err) {
    console.warn('[growthbook][plugin] Failed to fetch feature definitions from GrowthBook', err);
  }
  function getMemberIdFromCookie() {
    const memberIdCookie = app.$cookies.get('member_id') || '';
    const memberId = parseInt(memberIdCookie.split('member_')[1] || '', 10);
    return isNaN(memberId) ? undefined : memberId;
  }
  inject('growthbook', {
    isFeatureOn: (feature, attributes = {}) => {
      const memberId = getMemberIdFromCookie();
      const routeFullPath = route.fullPath;
      growthbookClient.setAttributes({
        ...growthbookClient.getAttributes(),
        memberId,
        routeFullPath,
        ...attributes
      });
      return growthbookClient.isOn(feature);
    },
    getFeatureValue: (feature, fallback, attributes = {}) => {
      const memberId = getMemberIdFromCookie();
      const routeFullPath = route.fullPath;
      growthbookClient.setAttributes({
        ...growthbookClient.getAttributes(),
        memberId,
        routeFullPath,
        ...attributes
      });
      return growthbookClient.getFeatureValue(feature, fallback);
    }
  });
}