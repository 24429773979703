// AFFILIATE PII CAPTURE
// example: https://extra.app/?_ef_transaction_id=&oid=1&affid=386&source_id={{ad.subId}}&sub1={{clickId}}&sub2={{date}}&firstname={{lead.personal.firstName}}&lastname={{lead.personal.lastName}}&email={{lead.personal.email}}&phone={{lead.personal.phone}}dob={{lead.personal.dob}}&address={{lead.address.line1}}&city={{lead.address.city}}&state={{lead.address.state}}&zip={{lead.address.zip}}&date={{date}}
import { shouldAllowTrackers } from '~/utils/head';
// firstname=, lastname=, email=, phone=, address=, city=, state=, zip=
export function captureAffPII(ctx) {
  var _a, _b, _c;
  console.log('AFF_PII_CAPTURE: init', process.browser ? 'browser' : 'server');
  if (!process.server && ctx.route && ctx.route.path.includes('iframe_')) {
    console.log('AFF_PII_CAPTURE: browser or iframe');
    // check and purge from client side (as server side cant check for localStorage)
    const allowTrackers = shouldAllowTrackers(ctx.route.path, window);
    if (!allowTrackers.Advertising) {
      console.log(`AFF_PII_CAPTURE: advertising trackers disallowed, purging cookies`);
      ctx.app.$cookies.remove('extra_aff_pii');
      return;
    }
  }
  const queryParams = ['first_name', 'last_name', 'firstname', 'lastname', 'email', 'phone', 'address', 'city', 'state', 'zip'];
  const captured = {};
  for (let param of queryParams) {
    console.log('AFF_PII_CAPTURE: param:', param);
    const paramVal = ctx.route.query[param];
    if (paramVal) {
      if (param === 'firstname') param = 'first_name';
      if (param === 'lastname') param = 'last_name';
      captured[param] = paramVal;
    }
  }
  function extractDomain(h) {
    return '.' + ([(h || '').split('.').reverse()[1], (h || '').split('.').reverse()[0]].filter(e => e).join('.').split(':')[0] || 'extra.app');
  }
  function toBase64(input) {
    let output = input;
    if (typeof Buffer !== 'undefined') {
      output = Buffer.from(input).toString('base64');
    } else if (typeof btoa !== 'undefined') {
      output = btoa(input);
    }
    return output;
  }
  if (Object.keys(captured).length) {
    console.log('AFF_PII_CAPTURE: json:', captured);
    ctx.app.$cookies.set('extra_aff_pii', toBase64(JSON.stringify(captured)), {
      maxAge: 60 * 60 * 24 * 30,
      // 30 days
      secure: true,
      domain: extractDomain(((_b = (_a = ctx.req) === null || _a === void 0 ? void 0 : _a.headers) === null || _b === void 0 ? void 0 : _b.host) || ((_c = ctx.route) === null || _c === void 0 ? void 0 : _c.fullPath))
    });
  }
}
export default function (ctx) {
  return captureAffPII(ctx);
}