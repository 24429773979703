const analyticsMock = () => {
  const noop = () => {};
  if (typeof window !== 'undefined') {
    window.extra_data_ul = {
      init: noop,
      sendData: noop,
      log: noop,
      flush: noop,
      setToken: noop
    };
    window.ga = noop;
  }
};
export default analyticsMock;