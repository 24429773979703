import affEverflowMiddleware from '../../middleware/aff-everflow';
import analyticsPlugin from './analytics';
import analyticsMock from './analytics.mock';
import analyticsHubPlugin from './analyticsHub';
import analyticsHubMock from './analyticsHub.mock';
import datadogPlugin from './datadog';
import datadogMock from './datadog.mock';
import growthbookPlugin from './growthbook';
import growthbookMock from './growthbook.mock';
import { shouldAllowTrackers } from '~/utils/head';
// import affPiiCaptureMiddleware from '../../middleware/aff-pii-capture'
export default (async (ctx, inject) => {
  if (!process.browser) {
    return;
  }
  // const userConsent = app.$cookies.get('userConsent')
  const allowTrackers = shouldAllowTrackers(ctx.route.path, window);
  for (const key in allowTrackers) {
    if (!allowTrackers[key]) {
      console.log('[trackers opt-out] using mocks instead for: ' + key);
    }
  }
  const plugins = [{
    name: 'Analytics',
    real: analyticsPlugin,
    mock: analyticsMock,
    allow: allowTrackers.Advertising
  }, {
    name: 'AnalyticsHub',
    real: analyticsHubPlugin,
    mock: analyticsHubMock,
    allow: allowTrackers.Analytics
  }, {
    name: 'Datadog',
    real: datadogPlugin,
    mock: datadogMock,
    allow: allowTrackers.Functional
  }, {
    name: 'Growthbook',
    real: growthbookPlugin,
    mock: growthbookMock,
    allow: allowTrackers.Functional
  },
  // MIDDLEWARES GET CALLED ONLY ON ROUTE CHANGE, BUT WE NEED IT ON PAGE LOAD TOO (make sure you apply it both places)
  {
    name: 'AffEverflow',
    real: affEverflowMiddleware,
    mock: null,
    allow: allowTrackers.Advertising
  }
  // ../../middleware/aff-pii-capture'
  ];
  for (const {
    name,
    real,
    mock,
    allow
  } of plugins) {
    try {
      const module = !allow ? mock : real;
      console.log('[trackers] init ' + name + ' as ' + (allow ? 'real' : 'mock'));
      await (module === null || module === void 0 ? void 0 : module(ctx, inject));
    } catch (error) {
      console.error('error loading tracker module ' + name + ':', error);
    }
  }
});