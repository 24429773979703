import PageHeader from '~/components/common/PageHeader.vue';
import PageFooter from '~/components/common/PageFooter.vue';
// import LoadingOverlay from '~/components/common/LoadingOverlay.vue'

export default {
  components: {
    PageHeader,
    PageFooter
    // LoadingOverlay
  },
  layout: '_base',
  computed: {
    // headerFixed () {
    //   return this.$route && this.$route.matched.map((r) => {
    //     return (r.components.default.options ? r.components.default.options.headerFixed : r.components.default.headerFixed)
    //   })[0]
    // },
  },
  mounted() {
    if (this.$route.hash) {
      this.scrollToHash();
    }
  },
  methods: {
    scrollToHash() {
      const hash = this.$route.hash;
      this.$nextTick(() => {
        this.$scrollTo(hash);
      });
    }
  }
};