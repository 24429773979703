import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
export default function ({
  $config
}, inject) {
  if (process.browser) {
    datadogRum.init({
      applicationId: 'a719d72f-38d3-4fcc-9174-31dfcf3c3ab0',
      clientToken: 'pub4b3f7bfba8cfac591749589c07addf91',
      site: 'datadoghq.com',
      env: $config.datadogEnv,
      service: $config.datadogService,
      version: $config.datadogVersion,
      sampleRate: 100,
      sessionReplaySampleRate: 100,
      // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true,
      trackFrustrations: true,
      trackUserInteractions: true,
      enableExperimentalFeatures: ['clickmap']
    });
    datadogRum.startSessionReplayRecording();
    console.log('Datadog RUM initialized');
    console.log(`env=${$config.datadogEnv}, service=${$config.datadogService}, version=${$config.datadogVersion}`);
    datadogLogs.init({
      clientToken: 'pub4b3f7bfba8cfac591749589c07addf91',
      site: 'datadoghq.com',
      env: $config.datadogEnv,
      service: $config.datadogService,
      version: $config.datadogVersion,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sampleRate: 100
    });
    inject('ddRum', datadogRum);
    inject('ddLogs', datadogLogs);
  }
}