/* eslint-disable no-throw-literal */
/* eslint-disable no-prototype-builtins */
// built by Biren, ported by Roland
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import async from 'async';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { v4 as uuidV4 } from 'uuid';
export default function ({
  app,
  $config
}) {
  function log() {
    if ($config.environment !== 'production') {
      console.debug('[analytics]', ...arguments);
    }
  }
  log('analytics setup');
  // skip banklookupiframe/emailboxiframe and manually enable fb pixel
  if (!window.location.pathname.includes('iframe_')) {
    const external_id = app.$cookies.get('ajs_anonymous_id') || uuidV4();
    external_id !== app.$cookies.get('ajs_anonymous_id') && app.$cookies.set('ajs_anonymous_id', external_id);
    app.$fb.setUserData({
      external_id
    });
    const eventID = uuidV4();
    const eventName = 'PageView';
    // app.$fb.enable() = app.$fb.init(); app.$fb.track()
    app.$fb.isEnabled = true; // need to manually set to enabled
    app.$fb.enable();
    app.$fb.init();
    app.$fb.track(eventName, {}, {
      eventID
    });
    app.$axios.$post('/api/fbevent', {
      eventName,
      member: {
        external_id
      },
      eventId: eventID
    }).catch(err => {
      console.log(`[fbEvent] ${eventName} CAPI failed for mid: ${this.memberProfile.id} with error: ${err}`);
    });
    log(['facebook pixel init', external_id]);
  }
  const extra_data_ul = window.extra_data_ul || {};
  extra_data_ul.uri_base = null;
  extra_data_ul.MAX_BUFFER_LENGTH = 0;
  extra_data_ul.g_source_map = {};
  extra_data_ul.heartbeat_frequency = 5000;
  extra_data_ul.heartbeat_interval = null;
  window.g_user_tag = null;
  window.g_device_tag = null;
  window.g_session_start_time = null;
  window.base_event = {};
  window.extra_data_ul = extra_data_ul;
  extra_data_ul.init = function (URI_BASE, MAX_BUFFER_LENGTH, base_event) {
    log('init', base_event);
    extra_data_ul.uri_base = URI_BASE;
    if (MAX_BUFFER_LENGTH !== null) {
      extra_data_ul.MAX_BUFFER_LENGTH = MAX_BUFFER_LENGTH;
    }
    const urlParams = new URLSearchParams(window.location.search);
    // set click_id cookies if appropriate params are present
    if (urlParams.get('fbclid')) Cookies.set('click_id', 'fbclid');
    if (urlParams.get('gclid') || urlParams.get('gbraid') || urlParams.get('wbraid')) Cookies.set('click_id', 'gclid');
    let device_tag = localStorage.getItem('device_tag') || Cookies.get('device_tag') || urlParams.get('device_tag');
    let new_device = false;
    if (!device_tag) {
      device_tag = 'br' + randomString(32);
      localStorage.setItem('device_tag', device_tag);
      new_device = true;
    }
    window.g_user_tag = localStorage.getItem('user_tag') || Cookies.get('user_tag') || urlParams.get('user_tag');
    window.g_device_tag = device_tag;
    window.g_session_tag = randomString(32);
    window.g_session_start_time = new Date();
    window.g_base_event = base_event || {};
    localStorage.setItem('device_tag', device_tag);
    Cookies.set('device_tag', device_tag, {
      domain: '.extra.app'
    });
    if (window.g_user_tag) {
      localStorage.setItem('user_tag', window.g_user_tag);
      Cookies.set('user_tag', window.g_user_tag, {
        domain: '.extra.app'
      });
    }
    // window.moesif && window.moesif.identifySession(window.g_session_tag);
    extra_data_ul.event_index = localStorage.getItem('extra_data_ul_event_index') || 0;
    // heartbeats
    // extra_data_ul.heartbeat_interval && clearInterval(extra_data_ul.heartbeat_interval)
    // extra_data_ul.heartbeat_interval = setInterval(function() {
    // 	if ($config.environment !== 'production') {
    // 		return
    // 	}
    //   window.extra_data_ul.log([
    //     {
    //       kingdom: 'session',
    //       phylum: 'session_heartbeat',
    //       float1: window.extra_data_ul.heartbeat_frequency / 1000
    //     }
    //   ])
    // }, extra_data_ul.heartbeat_frequency)
    // log('heartbeat init', extra_data_ul.heartbeat_frequency)
    // install
    const windowParams = {
      url: window.location.href,
      params: window.location.search,
      user_agent: navigator.userAgent,
      params_obj: Object.fromEntries(urlParams),
      referrer: document.referrer
    };
    if (new_device) {
      extra_data_ul.log([{
        event: 'install',
        kingdom: urlParams.get('utm_source'),
        phylum: urlParams.get('utm_content'),
        ...windowParams
      }]);
    }
    // session start
    extra_data_ul.log([{
      kingdom: 'session',
      phylum: 'session_start',
      subclass: urlParams.get('utm_source'),
      family: urlParams.get('utm_content'),
      ...windowParams
    }]);
  };
  extra_data_ul.sendData = function (data_source, schema_ver, raw_data) {
    const g_source_map = extra_data_ul.g_source_map;
    if (_.isEmpty(raw_data)) {
      throw 'extra-data-upload: Cannot send empty data';
    }
    if (!data_source || !schema_ver) {
      throw 'extra-data-upload: data source and schema version are required';
    }
    if (!g_source_map.hasOwnProperty(data_source)) {
      g_source_map[data_source] = {};
    }
    if (!g_source_map[data_source].hasOwnProperty(schema_ver)) {
      g_source_map[data_source][schema_ver] = [];
    }
    dayjs.extend(utc);
    const datetime = dayjs.utc(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const data = [];
    _.each(raw_data, function (d) {
      const event = _.extend({}, window.g_base_event, d);
      event.event_index = extra_data_ul.event_index;
      event.user_tag = window.g_user_tag || window.g_device_tag;
      event.device_tag = window.g_device_tag;
      event.member_tracking_tag = Cookies.get('extra_member_tag');
      event.extra_user_id = Cookies.get('extra_user_id');
      event.member_id = Cookies.get('member_id');
      event.session_tag = window.g_session_tag;
      event.event_datetime = datetime;
      data.push(event);
      extra_data_ul.event_index++;
    });
    localStorage.setItem('extra_data_ul_event_index', extra_data_ul.event_index);
    g_source_map[data_source][schema_ver] = g_source_map[data_source][schema_ver].concat(data);
    const buffer_len = g_source_map[data_source][schema_ver].length;
    extra_data_ul.g_source_map = g_source_map;
    if ($config.environment !== 'production') {
      if (data[0].phylum !== 'session_heartbeat') {
        log('captured', data[0]);
      }
      return;
    }
    if ($config.reportETL === 'false') {
      return;
    }
    if (buffer_len > extra_data_ul.MAX_BUFFER_LENGTH) {
      extra_data_ul.flush();
    }
  };
  extra_data_ul.log = function (data, data_source = 'extra-website', schema_ver = 2) {
    window.extra_data_ul.sendData(data_source, schema_ver, data);
  };
  extra_data_ul.flush = function (done) {
    if (!done || typeof done !== 'function') {
      done = function () {};
    }
    const map_to_flush = extra_data_ul.g_source_map;
    extra_data_ul.g_source_map = {};
    async.eachOf(map_to_flush, function (map_to_flush_by_schema, data_source, cb) {
      async.eachOfSeries(map_to_flush_by_schema, function (json_data, schema_ver, each_done) {
        const url = extra_data_ul.uri_base + 'track/buffer/' + data_source + '/' + schema_ver;
        log('sending data: ', json_data);
        fetch(url, {
          method: 'post',
          body: JSON.stringify(json_data),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          log('successfully sent data', res);
          each_done();
        }).catch(err => {
          console.warn('Error flushing data. Err: ', err);
          extra_data_ul.g_source_map[data_source] = {};
          extra_data_ul.g_source_map[data_source][schema_ver] = json_data;
          setTimeout(extra_data_ul.flush, 2000);
          each_done();
        });
      }, function (err) {
        cb(err);
      });
    }, function (err) {
      done(err);
    });
  };
  extra_data_ul.setToken = function (token) {
    try {
      const decoded = JSON.parse(atob((token || '').split('.')[1]));
      Cookies.set('extra_user_id', decoded && decoded.user && decoded.user.id);
    } catch (err) {
      log('couldnt parse token');
    }
  };
  function randomString(length, chars) {
    chars = chars || '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  const tags = [Cookies.get('extra_exp_nowall') && 'pricewall-off'].filter(e => e);
  const URI_BASE = 'https://track.extra.app/';
  const base_event = {
    app: 'extra-website',
    app_ver: 'control-021',
    config_ver: tags.join('|'),
    event: 'event'
  };
  extra_data_ul.init(URI_BASE, 0, base_event);
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-TRQJC89');
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  // prettier-ignore
  gtag('js', new Date());
  // gtag('config', 'AW-429071169'); // remove these and just use GTM-TRQJC89
  // gtag('config', 'UA-189215721-1'); // remove these and just use GTM-TRQJC89
  // prettier-ignore
  (function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    i[r] = i[r] || function () {
      // prettier-ignore
      (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = 1 * new Date();
    a = s.createElement(o), m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  window.ga('create', $config.gaTrackingID, 'auto');
  window.ga('require', 'OPT-W7FKRP6');
  (function (b, r, a, n, c, h, _, s, d, k) {
    if (!b[n] || !b[n]._q) {
      for (; s < _.length;) c(h, _[s++]);
      d = r.createElement(a);
      d.async = 1;
      d.src = 'https://cdn.branch.io/branch-latest.min.js';
      k = r.getElementsByTagName(a)[0];
      k.parentNode.insertBefore(d, k);
      b[n] = h;
    }
  })(window, document, 'script', 'branch', function (b, r) {
    b[r] = function () {
      b._q.push([r, arguments]);
    };
  }, {
    _q: [],
    _v: 1
  }, 'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'.split(' '), 0);
  // init Branch
  if (window.branch) {
    window.branch.init('key_live_aoZRsFyOEpJrsEYncER4hpogAulp4GKT');
    window.branch.data(function (err, data) {
      console.log('branch data:', err, data);
    });
    window.branch.first(function (err, data) {
      console.log('branch first:', err, data);
    });
  }
  // send the first page hit after you are assigned the experiment
  // setTimeout(function(){ window.ga('send', 'pageview'); }, 1000)
  // custom code to replicate @nuxtjs/gtm's behavior, with the native GTM implementation
  app.router.afterEach(to => {
    try {
      setTimeout(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          routeName: to.name,
          pageType: 'PageView',
          pageUrl: to.fullPath,
          pageTitle: typeof document !== 'undefined' && document.title || '',
          event: 'nuxtRoute'
        });
      }, 1);
    } catch (err) {
      console.error('failed to report route change', err);
    }
  });
}