import PageHeader from '~/components/common/PageHeader.vue';
import { generateMetaHead } from '~/utils/head';
export default {
  components: {
    PageHeader
  },
  layout: '_base',
  data() {
    return {
      fromApp: false
    };
  },
  head() {
    return generateMetaHead({
      title: 'Card Members Area | Extra',
      description: 'Already a Member of Extra? Set up your Extra Card by logging in to our Members Area.',
      routePath: this.$route.path
    });
  },
  mounted() {
    var _window;
    this.fromApp = !!((_window = window) !== null && _window !== void 0 && _window.sessionStorage.getItem('EXTRA_FROM_APP'));
  }
};