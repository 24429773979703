import { generateMetaHead } from '~/utils/head';
export default {
  layout: '_base',
  head() {
    return generateMetaHead({
      title: 'Login Members Area | Extra',
      description: 'Login to our Members Area to set up your Extra Card.',
      routePath: this.$route.path
    });
  }
};