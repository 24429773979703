import path from 'path';
export const ogBase = {
  title: 'Extra Debit Card® - Build Credit Without a Credit Card',
  description: 'Make the most of everyday purchases. Build credit*, and earn reward points* on every purchase. No credit checks, no hidden fees, and no interest rates, ever.',
  keywords: 'extra, debit, card, credit, credit building, rewards, points',
  url: process.env.BASE_URL || 'https://extra.app',
  image: 'https://s3.extra.app/site-assets/og-2022-1.jpg'
};
export function generateMetaHead(options = {}) {
  const {
    title,
    description,
    keywords,
    url,
    image,
    routePath,
    trackersAllowed,
    customTrackers,
    htmlAttrs,
    meta,
    link,
    script,
    ...rest
  } = {
    ...ogBase,
    ...options
  };
  return {
    title,
    htmlAttrs: {
      lang: 'en',
      ...(htmlAttrs || [])
    },
    meta: [{
      charset: 'utf-8'
    }, {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    }, {
      hid: 'description',
      name: 'description',
      content: description
    }, {
      hid: 'keywords',
      name: 'keywords',
      content: keywords
    }, {
      hid: 'og:type',
      name: 'og:type',
      content: 'website'
    }, {
      hid: 'og:url',
      name: 'og:url',
      content: url
    }, {
      hid: 'og:title',
      name: 'og:title',
      content: title
    }, {
      hid: 'og:description',
      name: 'og:description',
      content: description
    }, {
      hid: 'og:image',
      name: 'og:image',
      content: image
    }, {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image'
    }, {
      hid: 'twitter:url',
      name: 'twitter:url',
      content: url
    }, {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title
    }, {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: description
    }, {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: image
    }, ...(meta || [])],
    // trying to preload a stylesheet here causes it to be loaded multiple times, so TypeKit is loaded in app.html
    link: [{
      hid: 'icon',
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.png'
    }, routePath && {
      hid: 'canonical',
      rel: 'canonical',
      href: path.join(ogBase.url, routePath || '')
    }, ...(link || [])].filter(Boolean),
    ...rest,
    script: [...(script || []),
    // this is first party and functionality driven, so supposedly okay?
    {
      hid: 'referral-pixel',
      src: '/min/referral-pixel.js' /* + '?v=' + Date.now() */,
      async: true
    }, {
      hid: 'block-referrer',
      src: 'https://s3.extra.app/site-assets/block-referrer.min.js'
    }, ...(trackersAllowed && generateTrackersHead(customTrackers).script || [])]
  };
}
export function generateTrackersHead(otherTrackers) {
  return {
    script: [{
      hid: 'everflow-loader',
      src: 'https://www.tj82mrk.com/scripts/sdk/everflow.vanilla.js',
      async: true
    }, {
      hid: 'everflow-pixel',
      src: '/min/everflow-pixel.js' /* + '?v=' + Date.now() */,
      async: true
    }, ...(otherTrackers || [])]
  };
}
export function shouldAllowTrackers(pagePath, window) {
  const userConsent = {
    Functional: false,
    Analytics: false,
    Advertising: false
  };
  const blocklist = ['/banklookupiframe_'];
  if (!window) {
    console.warn('shouldAllowTrackers: no window object');
  }
  // PER JESSE, THIS IS ACTUALLY OKAY, WE DONT HAVE TO GOVERN THIS ON OUR END
  // AS TRANSCEND WILL AUTOMATICALLY OVERRIDE THE TRACKER SCRIPTS WITHOUT
  // US NEEDING TO DO ANYTHING.
  // HOWEVER IFRAMES GET STICKY, SO WE'LL STICK TO CUSTOM GOVERNANCE
  // OVER IFRAMES' SCRIPTS
  if (!blocklist.includes(pagePath)) {
    userConsent.Functional = true;
    userConsent.Analytics = true;
    userConsent.Advertising = true;
  } else {
    let airgapState;
    try {
      airgapState = window.localStorage.getItem('tcmConsent') || '{}';
      console.log('airgapState', airgapState);
      const {
        purposes
      } = JSON.parse(airgapState);
      for (const key in purposes) {
        userConsent[key] = purposes[key];
      }
    } catch (err) {}
    // still perma-off on iframes
    userConsent.Advertising = false;
  }
  // ;(window as any)._checkConsent = () =>
  // 	console.log('shouldAllowTrackers', pagePath, window, airgapState, userConsent)
  return userConsent;
}