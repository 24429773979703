const datadogMock = (_, inject) => {
  const noop = () => {};
  inject('ddRum', {
    init: noop,
    startSessionReplayRecording: noop,
    setUser: noop
  });
  inject('ddLogs', {
    logger: console,
    addLoggerGlobalContext: noop
  });
};
export default datadogMock;