var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "__members-area"
    }
  }, [!_vm.fromApp ? _c('page-header', {
    attrs: {
      "fixed": true,
      "backdrop": false
    }
  }, [_c('nuxt-link', {
    attrs: {
      "to": "/members/logout"
    }
  }, [_vm._v("Log out")])], 1) : _c('page-header', {
    attrs: {
      "logo-href": false,
      "fixed": true,
      "backdrop": false
    }
  }, [_c('span')]), _vm._v(" "), _c('nuxt')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };