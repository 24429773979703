import axios from 'axios';
import { GrowthBook } from '@growthbook/growthbook';
import { getRudder } from '../../utils/rudderstack';
export function getAnalytics(config) {
  const isDev = config.environment !== 'production';
  let rudderClient = null;
  let initialized;
  const init = async () => {
    if (initialized) return;
    isDev && console.log('Initializing analyticsHub');
    initialized = true;
    if (config.rudderstackWriteKey && config.rudderEnabled) {
      rudderClient = await getRudder(config);
    }
  };
  const track = async (...args) => {
    await init();
    isDev && console.debug('[analyticsHub] track', args);
    try {
      rudderClient === null || rudderClient === void 0 ? void 0 : rudderClient.track(...args);
    } catch (err) {
      console.error('[rudder] failed to track', args, err);
    }
  };
  const page = async (...args) => {
    await init();
    isDev && console.debug('[analyticsHub] page', args);
    try {
      rudderClient === null || rudderClient === void 0 ? void 0 : rudderClient.page(...args);
    } catch (err) {
      console.error('[rudder] failed to page', args, err);
    }
  };
  const identify = async (...args) => {
    await init();
    isDev && console.debug('[analyticsHub] identify', args);
    try {
      rudderClient === null || rudderClient === void 0 ? void 0 : rudderClient.identify(...args);
    } catch (err) {
      console.error('[rudder] failed to identify', args, err);
    }
  };
  const alias = async (...args) => {
    await init();
    isDev && console.debug('[analyticsHub] alias', args);
    try {
      rudderClient === null || rudderClient === void 0 ? void 0 : rudderClient.alias(...args);
    } catch (err) {
      console.error('[rudder] failed to alias', args, err);
    }
  };
  const getAnonymousId = async () => {
    await init();
    const id = rudderClient === null || rudderClient === void 0 ? void 0 : rudderClient.getAnonymousId();
    isDev && console.debug('[analyticsHub] getAnonymousId', id);
    return id;
  };
  const setAnonymousId = async id => {
    await init();
    isDev && console.debug('[analyticsHub] setAnonymousId -> ', id);
    return rudderClient === null || rudderClient === void 0 ? void 0 : rudderClient.setAnonymousId(id);
  };
  return {
    track,
    page,
    identify,
    alias,
    getAnonymousId,
    setAnonymousId
  };
}
const analyticsHub = async function ({
  app,
  $config,
  $cookies
}, inject) {
  var _a;
  if (!process.browser) {
    return;
  }
  const growthbookClient = new GrowthBook();
  const result = await axios.get($config.growthbookEndpoint);
  growthbookClient.setFeatures(result.data.features);
  const analytics = getAnalytics({
    ...$config,
    rudderEnabled: growthbookClient.getFeatureValue('rudder-enabled', true)
  });
  // get anonymousId from cookies, otherwise Rudderstack will generate a new one
  // that's different from the one used on the homepage
  const cookieId = $cookies.get('ajs_anonymous_id');
  if (cookieId) {
    await analytics.setAnonymousId(cookieId);
  }
  inject('analyticsHub', analytics);
  try {
    // track page views
    (_a = app.router) === null || _a === void 0 ? void 0 : _a.afterEach(to => {
      try {
        setTimeout(async () => {
          // don't fire on iframes
          if (window.self === window.top) {
            app.$analyticsHub.page('Website Visited', to.name, {
              referrer: to.params.page,
              anonymousId: await analytics.getAnonymousId(),
              ...to.query,
              // the home page email box test passes email as a query param to /application - this causes phantom users to be created in the CIO MQL workspace
              ...(to.path === '/application' ? {
                email: undefined
              } : {})
            });
          }
        }, 1);
      } catch (err) {
        console.error('[analyticsHub] failed to report route change', err);
      }
    });
  } catch (err) {
    console.warn('[analyticsHub] error getting Growthbook flag');
  }
};
export default analyticsHub;